const emeaOpenOrdersFilters = {
  searchStringList: {
    type: "text",
    name: "search",
    stringToArray: true,
    data: null
  },
  soldToNumList: {
    type: "text",
    name: "sold_to_party",
    stringToArray: true,
    exclude: true,
    excludeKey: 'excludeSoldToNumList',
    data: null
  },
  excludeSoldToNumList: {
    type: "text",
    name: "excluded_sold_to_party",
    stringToArray: true,
    data: null
  },
  materialNumberList: {
    type: "text",
    name: "material",
    stringToArray: true,
    exclude: true,
    excludeKey: 'excludeMaterialNumberList',
    data: null
  },
  excludeMaterialNumberList: {
    type: "text",
    name: "excluded_material",
    stringToArray: true,
    data: null
  },
  documentTypeList: {
    type: "text",
    name: "sales_document_type",
    stringToArray: true,
    data: null
  },
  requestedDeliveryDateCustom: {
    type: "customdaterange",
    name: "requested_delivery_date",
    data: null
  },
  requestedDeliveryDate: {
    type: "customdaterange",
    name: "requested_delivery_date",
    shortName: 'RDD',
    data: null
  },
  originSites: {
    type: "text",
    name: "plant",
    stringToArray: true,
    data: null
  },
  deliveryBlocked: {
    type: "checkboxradio",
    name: "delivery_block",
    defaultValue: null,
    data: [
      { name: "blocked", value: "Y", checked: false },
      { name: "not_blocked", value: "N", checked: false }
    ]
  },
  orderReasonCodeList: {
    type: "text",
    name: "order_reason_code",
    stringToArray: true,
    exclude: true,
    excludeKey: 'excludeOrderReasonCodeList',
    data: null
  },
  excludeOrderReasonCodeList: {
    type: "text",
    name: "excluded_order_reason_code",
    stringToArray: true,
    data: null
  },
  divisionList: {
    type: "text",
    name: "division",
    stringToArray: true,
    data: null
  },
  itemStatusBucketList: {
    type: "checkbox",
    name: "status",
    data: [
      { name: "rejected", value: "REJECTED", checked: false },
      { name: "invoiced", value: "INVOICED", checked: false },
      { name: "under_credit_analysis", value: "UNDER_CREDIT_ANALYSIS", checked: false },
      { name: "delivery_block", value: "DELIVERY_BLOCK", checked: false },
      { name: "delivery", value: "DELIVERY", checked: false },
      { name: "on_shipment", value: "TRANSPORTATION_PLAN", checked: false }
    ]
  },
  corporateCustomerName: {
    type: "text",
    name: "customer",
    data: null
  },
  reqDelDateBucketList: {
    type: "checkbox",
    name: "rdd_bucket",
    data: [
      { name: "rdd_miss_one_to_seven_days", value: "RDD_MISS_ONE_TO_SEVEN_DAYS", checked: false },
      { name: "rdd_delayed_greater_than_seven_days", value: "RDD_DELAYED_GREATER_THAN_SEVEN_DAYS", checked: false },
      { name: "rdd_for_today", value: "RDD_FOR_TODAY", checked: false },
      { name: "rdd_for_tomorrow", value: "RDD_FOR_TOMORROW", checked: false },
      { name: "for_remaining_month", value: "RDD_FOR_REMAINING_MONTH", checked: false }
    ]
  },
  materialHierarchyDesc: {
    type: "text",
    name: "product_hierarchy",
    data: null
  },
}

export default emeaOpenOrdersFilters;