import React, { useEffect, useState } from "react";
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import useSetQuery, { useMultiSetQuery } from "customHooks/useQuery";
import { defaultOrderEntryDate, todayDocumentDate } from "configs/appConstants";
import { useEMEAGlobalContext } from 'context/EMEAGlobalContext';
import EMEABreadcrumb from "components/EMEA/EMEA_Common/EMEABreadcrumb";
import { emeaSummaryService } from 'reduxLib/services/EmeaServices/emeaSummaryService';
import { formatCompactNumber } from 'helpers/emeaHelpers';
import find from 'lodash/find';
import map from 'lodash/map';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette?.card?.default,
    borderRadius: theme.spacing(1)
  },
  grid: {
    padding: `${theme.spacing(3)}px 0px`,
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    display: 'grid',
    backgroundColor: theme.palette?.card?.miniCard,
    borderRadius: theme.spacing(1),
    boxShadow: '0px 2px 4px 1px rgba(0,0,0,0.12)',
    textAlign: 'center'
  },
  statusGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  red: {
    color: theme.palette?.text?.unhealthy,
    textAlign: "center",
  },
  blue: {
    color: theme.palette?.text?.healthy,
    textAlign: "center",
  },
  icon: {
    height: 'auto',
    marginRight: theme.spacing(1.5),
  },
  link: {
    color: theme.palette?.link?.primary,
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    }
  },
  box2: {
    borderLeft: `6px solid ${theme.palette?.extra?.infoHighlight}`,
    borderTop: `1px solid ${theme.palette?.primary?.active}`,
    borderBottom: `1px solid ${theme.palette?.primary?.active}`,
    borderRight: `1px solid ${theme.palette?.primary?.active}`,
  },
  bold: {
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    }
  },
  paper: {
    background: 'transparent',
    borderRadius: 0,
    display: 'inline-flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
    width: '19%',
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `0px ${theme.spacing(4)}px`
    }
  },
  divider: {
    borderRight: `2px solid ${theme.palette?.black}`,
    [theme.breakpoints.down("sm")]: {
      borderRight: 0
    }
  },
  statusDivider: {
    width: theme.spacing(.5),
    backgroundColor: theme.palette?.black,
    marginRight: theme.spacing(2),
  },
  buttonLink: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 4px 1px rgba(0,0,0,0.12)',
    backgroundColor: theme.palette?.secondary?.base,
    margin: theme.spacing(0.5),
    width: '100%'
  },
  totalbtnLink: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(.5),
    margin: `${theme.spacing(.75)}px ${theme.spacing(1.5)}px`,
    backgroundColor: theme.palette?.secondary?.button,
    boxShadow: '0px 2px 4px 1px rgba(0,0,0,0.12)',
    border: `1px solid ${theme.palette?.link?.default}`
  },
  horizontalDivider: {
    backgroundColor: theme.palette?.black,
    height: theme.spacing(.25),
    margin: theme.spacing(1)
  }
}));

export const findStatusObject = (list, key) => {
  const obj = find(list, ['state', key]);
  const value = obj?.totalCount ? formatCompactNumber(obj.totalCount) : '-';
  return value;
};

const EMEAOpenOrdersStatus = ({ pageName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const setMultiQuery = useMultiSetQuery();
  const [params] = useSetQuery();
  const history = useHistory();
  const location = useLocation();
  const [{ loadView }] = useSetQuery();
  const dispatch = useDispatch();

  const [isInvoiced] = useState(() => pageName === "invoiced");
  const [controller] = useState(() => new AbortController());
  const status_loading = useSelector(({ emeaSummaries }) => emeaSummaries?.["SUBTYPE_EMEA_OPEN_ORDERS_ITEM_STATUS"]?.loading);
  const status_data = useSelector(({ emeaSummaries }) => emeaSummaries?.["SUBTYPE_EMEA_OPEN_ORDERS_ITEM_STATUS"]?.data || []);
  const today_status_loading = useSelector(({ emeaSummaries }) => emeaSummaries?.["SUBTYPE_EMEA_OPEN_ORDERS_TODAYS_INFO"]?.loading);
  const today_status_data = useSelector(({ emeaSummaries }) => emeaSummaries?.["SUBTYPE_EMEA_OPEN_ORDERS_TODAYS_INFO"]?.data || []);
  const [totalStatus, setTotalStatus] = useState(0);

  const {
    locations,
    customers = [],
    sofcs = [],
    BU,
    documentDate
} = useEMEAGlobalContext();

  const statusData = [{
    name: t('under_credit_analysis'),
    state: "UNDER_CREDIT_ANALYSIS",
    styleClass: "red"
  }, {
    name: t('delivery_block'),
    state: "DELIVERY_BLOCK",
    styleClass: "red"
  }, {
    name: t('delivery'),
    state: "DELIVERY",
    styleClass: "blue"
  }, {
    name: t('on_shipment'),
    state: "TRANSPORTATION_PLAN",
    styleClass: "blue"
  }, {
    name: t('invoiced'),
    state: "INVOICED",
    styleClass: "blue"
  }];

  const rejectedData = {
    name: t('rejected'),
    state: "REJECTED",
    styleClass: "red"
  }

  const setHyperlinkFilters = ({ key, data, type }) => {
    if (isInvoiced) return;
    if (data === 'TOTAL') {
      data = map(statusData, 'state');
    }
    setMultiQuery({
        emeaFilters: JSON.stringify({
            [key]: type === "array" ? [data] : data
        }),
        isHyperlink: true
    });
  }

  const handleLinkClick = () => {
    let updatedPath = location.pathname;
    updatedPath = isInvoiced ?
      updatedPath.replace("/invoiced", "") :
      `${updatedPath}/invoiced`;
    history.push({
      ...location,
      pathname: updatedPath
    })
  };

  const refreshStatusKPI = ({ subType }) => {
    if (loadView) return null;

    const payload = {
      region: "EMEA",
      subtype: subType,
      businessUnits: BU,
      sites: locations,
      documentDate : (subType === "SUBTYPE_EMEA_OPEN_ORDERS_TODAYS_INFO") ? todayDocumentDate : documentDate,
      customerOrSalesOffice: [...customers, ...sofcs],
      currency: localStorage.getItem("currency") || "USD",
      orderType: "ORDER_ENTRY"
    }
    dispatch(emeaSummaryService({ payload, controller }));
  }

  useDeepCompareEffect(() => {
    refreshStatusKPI({ subType: "SUBTYPE_EMEA_OPEN_ORDERS_ITEM_STATUS" });
    !isInvoiced && refreshStatusKPI({ subType: "SUBTYPE_EMEA_OPEN_ORDERS_TODAYS_INFO" });
  }, [locations, sofcs, documentDate, customers, BU, localStorage.getItem("currency")]);

  useDeepCompareEffect(() => {
    let sum = status_data?.reduce((a, b) => {
      if (b?.state !== "REJECTED") 
        return a + b?.totalCount
      else return a
    }, 0);
    sum = sum ? formatCompactNumber(sum) : '-';
    setTotalStatus(sum);
}, [status_data]);

  useEffect(() => {
    if (params?.loadView) {
      setMultiQuery({
        documentDate: JSON.stringify(
          params.loadView ? defaultOrderEntryDate : params.documentDate
        ),
        loadView: undefined
      });
    }
  }, [params?.loadView, params?.documentDate]);

  useEffect(() => {
    return () => {
      controller.abort();
    }
}, []);

  const KPIBlocks = () => {
    return (
      <>
        <Box p={isMobile ? 0.5 : 2} className={classes.box} data-testid="open-orders-status-kpi">
          <Grid container justifyContent="space-between" >
            <Grid item className={classes.statusGrid}>
              <Typography variant="h3"> {t('open_orders')} - &nbsp; </Typography>
              <Typography variant="body1"> {t('by_status')} </Typography>
            </Grid>
            <Grid item >
              <Button className={classes.link} onClick={handleLinkClick} disableRipple data-testid="pagelink-btn" size='small' >
                {
                  isInvoiced ? t("goto_order_status") : t("goto_invoiced_openorders_gap")
                }
              </Button>
            </Grid>
          </Grid>
          {!isMobile ? 
          <Grid container>
            {statusData.map((item, index) =>
              <>
                <Grid container item xs={1} justifyContent="center" className={classes.grid} >
                  <Typography variant="h3" className={classes[item.styleClass]} >
                    { status_loading ? "..." : findStatusObject(status_data, item.state) } 
                  </Typography>
                  <Typography variant="body2" className={isInvoiced ? null : classes.link} data-testid={item.state}
                    onClick={() => {
                      setHyperlinkFilters({ key: "itemStatusBucketList", data: item.state, type: "array" })
                    }}
                  > 
                    { item.name } 
                  </Typography>
                </Grid>
                {(index === 4) ? <DragHandleIcon className={classes.icon} /> : <AddIcon className={classes.icon} />}
              </>
            )}
            <Grid container item xs={1} justifyContent="center" className={classes.grid} >
              <Typography variant="h1" className={classes.blue}> { status_loading ? "..." : totalStatus } </Typography>
              <Typography variant="body2" className={isInvoiced ? null : classes.link} data-testid={"totalStatus"}
                onClick={() => {
                  setHyperlinkFilters({ key: "itemStatusBucketList", data: "TOTAL", type: "" })
                }}
              > 
                {t('total_invoice')} + {t('open')} 
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem className={classes.statusDivider} />
            <Grid container item xs={1} justifyContent="center" className={classes.grid} >
              <Typography variant="h3" className={classes[rejectedData.styleClass]} > 
                { status_loading ? "..." : findStatusObject(status_data, rejectedData.state) } 
              </Typography>
              <Typography variant="body2" className={isInvoiced ? null : classes.link} data-testid={rejectedData.state}
                onClick={() => {
                  setHyperlinkFilters({ key: "itemStatusBucketList", data: rejectedData.state, type: "array" })
                }}
              > 
                { rejectedData.name } 
              </Typography>
            </Grid>
          </Grid> :
          <Box>
            <Grid container>
              <Grid container item xs={6}>
                {statusData.map((d) =>
                  <Button 
                    className={classes.buttonLink} 
                    data-testid={d.state}
                    onClick={() => {
                      setHyperlinkFilters({ key: "itemStatusBucketList", data: d.state, type: "array" })
                    }}
                  >
                    <Typography className={isInvoiced ? null : classes.link} > { d.name } </Typography>
                    <Typography className={classes[d.styleClass]} >
                      { status_loading ? "..." : findStatusObject(status_data, d.state) } 
                    </Typography>
                  </Button>
                )}
              </Grid>
              <Grid container item xs={6}>
                <Grid 
                    className={classes.totalbtnLink} 
                    data-testid={"totalStatus"}
                    onClick={() => {
                      setHyperlinkFilters({ key: "itemStatusBucketList", data: "TOTAL", type: "" })
                    }}
                  >
                    <Typography className={classes.blue} >
                      { status_loading ? "..." : totalStatus } 
                    </Typography>
                    <Typography className={isInvoiced ? null : classes.link} > { t('total_invoice')} + {t('open') } </Typography>
                  </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.horizontalDivider} />
            <Grid container xs={12}>
              <Button 
                className={classes.buttonLink} 
                data-testid={rejectedData.state}
                onClick={() => {
                  setHyperlinkFilters({ key: "itemStatusBucketList", data: rejectedData.state, type: "array" })
                }}
              >
                <Typography className={isInvoiced ? null : classes.link} > { rejectedData.name } </Typography>
                <Typography className={classes[rejectedData.styleClass]} >
                  { status_loading ? "..." : findStatusObject(status_data, rejectedData.state) } 
                </Typography>
              </Button>
            </Grid>
          </Box>
          }
        </Box>
        {isInvoiced ? null :
          <Box py={3} my={2} className={[classes.box, classes.box2]} >
            <Paper elevation={0} className={[classes.paper, classes.divider]}>
              <strong className={classes.bold}> { today_status_loading ? "..." : findStatusObject(today_status_data, 'DELIVERIES_CREATED_TODAY') } </strong>
              <Typography variant="body2"> {t('deliveries_created_today')} </Typography>
            </Paper>
            <Paper elevation={0} className={[classes.paper, classes.divider]}>
              <strong className={classes.bold}> { today_status_loading ? "..." : findStatusObject(today_status_data, 'DELIVERIES_CREATED_TODAY_BLOCKED') } </strong>
              <Typography variant="body2"> {t('deliveries_created_today')} - {t('blocked')} </Typography>
            </Paper>
            <Paper elevation={0} className={[classes.paper, classes.divider]}>
              <strong className={classes.bold}> { today_status_loading ? "..." : findStatusObject(today_status_data, 'NIV_ORDERS_CREATED_TODAYS') } </strong>
              <Typography variant="body2"> {t('niv_order_created_today')} </Typography>
            </Paper>
            <Paper elevation={0} className={[classes.paper, classes.divider]}>
              <strong className={classes.bold}> { today_status_loading ? "..." : findStatusObject(today_status_data, 'NIV_ORDERS_CREATED_TODAYS_BLOCKED') } </strong>
              <Typography variant="body2"> {t('niv_order_created_today')} - {t('blocked')} </Typography>
            </Paper>
            <Paper elevation={0} className={classes.paper}>
              <strong className={classes.bold}> { today_status_loading ? "..." : findStatusObject(today_status_data, 'INVOICES_CREATED_TODAY') } </strong>
              <Typography variant="body2"> {t('niv_invoices_today')} </Typography>
            </Paper>
          </Box>
        }
      </>
    )
  }

  return (
    <>
      <EMEABreadcrumb pageName={pageName} />
      <KPIBlocks />
    </>
  )
}

export default EMEAOpenOrdersStatus;